import React from "react"
import SEO from "../components/elements/SEO"

const NotFoundPage = () => (
    <main className="container is-fluid">
        <div className="page-404">
            <SEO title="404: Not found" />
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </main>
)

export default NotFoundPage
